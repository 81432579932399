import i18n from 'i18next';
import Locize from 'i18next-locize-backend';
import i18next from "i18next";
import {I18nextProvider} from "react-i18next";
import React from "react";
import {getLanguage} from "translations/actions";
import {getCompanyLocize} from "config/useCompany";


const {availableLanguages,...locizeOptions} = getCompanyLocize();
const language = getLanguage();
i18next.use(Locize).init({
  lng: language, //delete if by geolocalization
  fallbackLng: language,
  whitelist: availableLanguages,
  debug: true,
  saveMissing: true,
  load: 'all',
  backend: locizeOptions,
  locizeLastUsed: locizeOptions,
})
i18n.on('languageChanged', function (language) {
  localStorage.setItem('i18nextLng', language);
});


const ProviderI18 = ({children}) => {
  return (
    <I18nextProvider i18n={i18next}>
      {children}
    </I18nextProvider>
  )
}
export default ProviderI18;

