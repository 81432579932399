
import React from 'react'
import styled from "styled-components";

export const createItems = list => {
    return list.map( (item, key) => {
        return (
            <Item key={key}>
              <div>{ item.text }</div>
              <div>{ item.value }</div>
            </Item>    
        )
    })
}

export const Item = styled.div`
  display: flex;
  & > div:nth-child(1) {
      margin-right: 10px;
  }
  & > div {
      width: 50%;
  }
  margin: 10px 0;
`;
