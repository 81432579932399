import React, {useEffect, Fragment, useState} from 'react';
import {Field, Form, reduxForm} from "redux-form";
import './style.scss';
import {useTranslation} from "react-i18next";
import WrapperSection from "components/common/wrapperSection";
import {BannerForm} from "./templates"
import axios from "axios";

const CustomForm = ({handleSubmit, initialValues, submitting, reset, config: {components}, ...props}) => {
  const {contact} = components;
  const {t} = useTranslation();
  const [bannerType, setBannerType] = useState(null)
  useEffect(() => {
    if (bannerType) {
      setTimeout(() => setBannerType(null), 5000)
    }
  }, [bannerType])

  const submit = async (values) => {

    const {name, email, message} = values;

    const {REACT_APP_API_KEY,REACT_APP_API_CONTACT,REACT_APP_MAIL_CONTACT} = process.env;

    const requestBody = {
      email: REACT_APP_MAIL_CONTACT,
      subject: 'Contact',
      body: `
      <b>email: </b>${email}<br> 
      <b>name: </b>${name}<br>
      <b>message: </b>${message}
      `
    }
    axios.defaults.headers.common['apikey'] = REACT_APP_API_KEY
    try {
      await axios.post(`${REACT_APP_API_CONTACT}/whitelabel/v1/emails/send`, requestBody);
      setBannerType('success')
      reset();
    } catch (e) {
      setBannerType('error')
    }
  }



  const createFields = () => (contact.fields.map((field, index) => (
      <Fragment key={index}>
        {field.label && <label>{t(field.label)}</label>}
        <Field {...field} placeholder={t(field.placeholder)}/>
      </Fragment>
    ))
  )
  return (
    <WrapperSection className="contact" id="contact">
      <div className="wrap">
        <div className="side">
          <i className="icon-email"/>
          <div className="title">
            <div>{t(contact.title)}</div>
          </div>
        </div>
        <Form onSubmit={handleSubmit(submit)}>
          {createFields()}
          {
            bannerType &&
            <BannerForm bannerType={bannerType}/>
          }
          <button type="submit" disabled={submitting}>{t(contact.button)}</button>
        </Form>
      </div>
    </WrapperSection>
  )
}
const validate = values => {
  const errors = {};
  if (!values.recaptcha) {
    errors.recaptcha = 'captchaRequired';
  }
  if (!values.name) {
    errors.name = 'requiredField';
  }
  if (!values.email) {
    errors.email = 'requiredField';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'emailInvalid';
  }
  if (!values.message) {
    errors.message = 'requiredField';
  }
  return errors;
}
const CustomReduxForm = reduxForm({
  form: 'form',
  validate
})(CustomForm)

export default CustomReduxForm

