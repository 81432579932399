import {combineReducers} from "redux";
import {reducer as formReducer} from 'redux-form';

export const rootReducer = (state, action) => {

  if (action.type === "CLEAR_STORE") {
    state = undefined
  }
  return appReducer(state, action)
};

export default rootReducer;

const appReducer = combineReducers({
  form:formReducer,
});
