import React, { useState } from 'react';
import styled from 'styled-components';
import Play from 'assets/images/play.svg'

export const ImageVideo = ({ srcImage, srcVideo, setUrlVideo }) => {
  return (
    <div className="image-video" onClick={ () => setUrlVideo(srcVideo) }>
      <Image className="play" src={ Play } />
      <Image className="blend" src={ srcImage } />
    </div>
  )
}

export const Video = ({ urlVideo, setUrlVideo }) => {
  const [ visible, setVisible ] = useState(false)
  return (
    <div className={ `video-modal ${ visible && 'visible' }` }>
      <div className="shadow" />
        <div className="close" onClick={ () => setUrlVideo(null) }>X</div>
        <iframe className="video" src={ urlVideo } onLoad={ () => setVisible(true) } />
    </div>
  )
}

const Image = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: url(${props => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
