import React, { useState, useEffect, useRef } from 'react';
import LanguageSelector from '../common/dropdown'
import { MenuButtons, TopMenu, BottomMenu, MobileMenu, LinkBack } from './templates'
import './style.scss';

const Header = ({config}) => {
  const {components: {header}} = config;
  const {logo,links,languageSelector} = header;
  const [ scrolled, setScrolled ] = useState(false)
  const ref = useRef()

  const manageScroll = () => {
    if(window.scrollY > 0 && !ref.current) {
      setScrolled(true)
      ref.current = true
    } else if(window.scrollY === 0) {
      setScrolled(false)
      ref.current = false
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', manageScroll)
    return () => {
      window.removeEventListener('scroll', manageScroll)
    }
  }, [])

  return (
    <div id='header' className={ scrolled && 'scroll' }>
      <div className="wrap">
        <TopMenu>
          <img src={ logo }/>
          <LanguageSelector languages={languageSelector.languages}/>
        </TopMenu>

        <BottomMenu>
          <MenuButtons links={links}/>
        </BottomMenu>

        <LanguageSelector languages={languageSelector.languages}/>
        <MobileMenu links={links} languages={languageSelector.languages}/>

        <LinkBack backLink={header.backLink}/>

      </div>

    </div>
  )
};
export default Header;

