import React from 'react';
import Carousel from '../common/carousel'
import Underline from 'components/common/underline'
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css";
import { CustomSlider, createSlides } from "./templates"
import {useTranslation} from "react-i18next";
import './style.scss';
import WrapperSection from "components/common/wrapperSection";


const Testimony = ({config: {components}}) => {

  const {testimony} = components;
  const {t} = useTranslation();
  const params = { testimony, t }

  return (
    <WrapperSection id="testimony">
      <Underline>{t(testimony.title)}</Underline>
      <CustomSlider { ...params }/>
      {/*<Carousel breakPoint={956} kind="testimony">{createSlides(params)}</Carousel>*/}
    </WrapperSection>
  )
};
export default Testimony;
