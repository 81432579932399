import React from 'react';
import Image from 'components/common/image'
import Underline from 'components/common/underline'
import './style.scss';
import {useTranslation} from "react-i18next";
import WrapperSection from "components/common/wrapperSection";


const Location =({config: {components}}) => {
  const {location} = components;
  const {t} = useTranslation();
  return (
    <WrapperSection className="location" id="location">
      <div className="wrap">
        <Underline>{t(location.title)}</Underline>
        <div className="title">{t(location.subtitle)}</div>
        <img src={location.image}/>
      </div>

    </WrapperSection>
  )
};
export default Location;

