import React, { useState } from 'react';
import styled from 'styled-components';
import LanguageSelector from '../common/dropdown'
import { useTranslation } from "react-i18next";
import { Link } from "@reach/router";
import './style.scss';

export const LinkBack = ({backLink}) =>{
  const {text,href} = backLink;
  const {t} = useTranslation()

  return (
    <div className="link-back">
       <Link to={href}><i className="icon-arrow-left"></i>{ t(text) }</Link>
    </div>
  )
}

export const TopMenu = styled.div.attrs({
  className: "top-menu"
})``

export const BottomMenu = styled.div.attrs({
  className: "bottom-menu"
})``

export const MenuButtons = ({links}) => {
    const { t, i18n } = useTranslation()
    return links.map(link=>(
      <a key={link.text} href={link.href}>{t(link.text)}</a>
    ))
}


export const MobileMenu = ({links,languages}) => {
    const [display, setDisplay] = useState(false)
    return (
        <div className="menu-burger">
          <i class="icon-burguer" onClick={ () => setDisplay(true) } />
          <div className={`aside-menu-wrapper ${display && 'show'}`}>
            <div className="shadow"  onClick={ () => setDisplay(false) }/>
            <div className={`aside-menu`}>
              <i className="icon-close close" onClick={ () => setDisplay(false) }/>
              <MenuButtons links={links}/>
              <LanguageSelector languages={languages}/>
            </div>
          </div>

        </div>
    )
}

export const Image = styled.div`
  background: url(${props => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  width: 20px;
  height: 20px;
  &:hover {
    opacity: 0.5;
  }
`;
