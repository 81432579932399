export const MD = 'meetingdoctors'
export const MV = 'meetingvets'
export const MD_URL = 'https://www.meetingdoctors.com';
export const MV_URL = 'https://www.meetingvets.com';
export const ML_URL = 'https://www.meetinglawyers.com';
export const BCN_OFFICE_URL ='https://goo.gl/maps/qWd9enCFJsmJn5yr8';
export const BCN_OFFICE_ADDRESS = 'C. Milanesat, 25, 08017 Barcelona';
export const MX_OFFICE_URL ='https://goo.gl/maps/bQs1MCNmwpGDLhWZ9';
export const MX_OFFICE_ADDRESS = 'C. Darwin, 74, 11590, CDMX, México';
export const AE_OFFICE_URL ='https://goo.gl/maps/r3aYE1mebrNkwxFE9';
export const AE_OFFICE_ADDRESS = 'Jumeirah Lake Towers, Dubai, EAU';
