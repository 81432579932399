import styled, {css} from "styled-components";
import {useTranslation} from "react-i18next";
const SUCCESS = 'success'

export const BannerForm = ({bannerType}) => {
    const {t} = useTranslation()
    if(bannerType === SUCCESS) return <Banner success>{t('successFormText')}</Banner>
    return <Banner error>{t('errorFormText')}</Banner>
}

const Banner = styled.div`
    padding: 10px;
    box-sizing: border-box;
    border-radius: 4px;
    ${({ success }) => success && css`
      background-color: #d4edda;
      color: #155724;
      border-color: #c3e6cb;
    `}
    ${({ error }) => error && css`
      color: #721c24;
      background-color: #f8d7da;
      border-color: #f5c6cb;
    `}
`;
