import React from 'react';
import {useTranslation} from "react-i18next";

export const InputField = ({input,placeholder, meta}) => {
  const {t} = useTranslation();
  return(
    <>
      <input
        placeholder={placeholder}
        value={input.value}
        onChange={e =>input.onChange(e.target.value)}
        onFocus={ input.onFocus }
        onBlur={ input.onBlur }
      />
      {meta && meta.touched && meta.error && <span className="error-validation">{t(meta.error)}</span>}
    </>
  )

}
