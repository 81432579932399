import React from 'react';
import styled from "styled-components";
const WrapperSection = (props) => {
  const {children,...otherProps} = props;
  return (
    <section {...otherProps}>
      <Wrapper>
        {children}
      </Wrapper>
    </section>
  )
}
export default WrapperSection;

const Wrapper = styled.div`
  max-width: 1330px;
  width: 100%;
  margin: 0 auto;
`;
