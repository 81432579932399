const getLanguageFromDomain = () => {
  const topLevelDomain = window.location.hostname.split('.').pop();

  const domainsLanguage = {
    io: 'es',
    com: 'es',
    mx: 'es',
    lat: 'es',
    br: 'pt',
    default: 'en'
  }
  const initialLanguage = domainsLanguage[topLevelDomain] || domainsLanguage["default"];
  return initialLanguage;
}

export const getLanguage = () => {
  const cacheLanguage = localStorage.getItem('i18nextLng');
  if (!cacheLanguage) return getLanguageFromDomain();
  return cacheLanguage;
}
