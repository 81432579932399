

const scrollAnimation = () => {
    document.querySelectorAll('a').forEach((elem) => {
        if(!elem.href.includes('#')) return
        elem.addEventListener('click', event => {
          event.preventDefault();
          let hash = event.currentTarget.hash
          let section = document.querySelector([hash=`${hash}`])
          let main = document.querySelector('html')
          main.scrollTop = section.offsetTop -120
        })
    })
}

export const manageScroll = () => {
    document.addEventListener('DOMContentLoaded', function() {
        setTimeout(() => scrollAnimation(), 2000)
    })
}

export const scrollTop = () => {
    document.querySelector('html').scrollTop = 0;
  }
