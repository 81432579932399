import React from 'react';
import Underline from 'components/common/underline'
import './style.scss';
import {useTranslation} from "react-i18next";


const Milestone = ({config: {components}}) => {
  const {milestone} = components;
  const {t} = useTranslation();

  const createContent = () => (
    milestone.list.map(item => (
        <div className="item"  data-aos="fade-down">
          <div className="number">{ item.number }</div>
          <div className="description">{ t(item.text) }</div>
        </div>
      )
    )
  )

  return (
    <section id="milestone" style={{backgroundImage: `url(${milestone.image})`}}>
      <div className="wrap">
        <Underline>{t(milestone.title)}</Underline>
        <div className="list">
          {createContent()}
        </div>
      </div>
    </section>
  )
};
export default Milestone;
