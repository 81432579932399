
import React, { useState } from 'react';
import Chevron from 'assets/images/chevron-down.png'
import {createList, Image} from './templates'
import { useTranslation } from "react-i18next";
import './style.scss';


const LanguageSelector = ({languages}) => {
  const { i18n } = useTranslation()
  const currentLanguage = languages.find(lang=>lang.value===i18n.language);
  // const defaultValue = { text: 'Español', value: 'es', icon: Spain }
  const [value, setValue] = useState(currentLanguage)
  const [display, setDisplay] = useState(false)

  const updateLanguage = language => {
    i18n.changeLanguage(language?.value)
    setValue(language)
  }
  const params = { setDisplay, updateLanguage,languages }

  return (
    <section className="dropdown" onClick={ () => setDisplay(!display) }>
      <div className="trigger">
          <Image src={ value.icon } />
          { `${ value.text }` }
          <Image src={ Chevron } className="chevron" />
      </div>
      {
          display &&
          <div className="list">
            { createList(params) }
          </div>
      }
    </section>
  )
};
export default LanguageSelector;
