import styled from 'styled-components';

export const createList = ({ updateLanguage, languages }) => {
    return languages.map( (item, key) => (
            <div className="item" key={ key } onClick={ () => updateLanguage(item) }>
              <Image src={ item.icon } />
              <div className="text">{ item.text }</div>
            </div>
        )
    )
}

export const Image = styled.div`
  background: url(${props => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  width: 25px;
  height: 25px;
  &:hover {
    opacity: 0.5;
  }
`;
