import React from 'react';
import { goBottom } from './actions'
import './style.scss';
import {useTranslation} from "react-i18next";

const Floating = ({config: {components}}) => {
  const {floatingButton} = components;
  const {t} = useTranslation()
  return (
    <div id="floating" onClick={ goBottom } data-aos="zoom-in">
      <i class="icon-email"></i>
      <div className="text">{ t(floatingButton) }</div>
    </div>
  )
};
export default Floating
