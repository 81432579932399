import Intro from 'config/md/assets/images/bg-intro.jpg';
import Map from 'config/md/assets/images/meeting-map.png';
import DoctorGo from 'config/md/assets/images/doctorgo.jpg'
import Ginecaneschat from 'config/md/assets/images/ginecaneschat.jpg'
import MobileGif from 'config/md/assets/images/gif_mobile_meeting.gif'
import MobilePic from 'config/md/assets/images/pic-mobile.png'
import LogoHeader from 'config/md/assets/images/logo-md.svg'
import LogoDoctors from 'assets/images/logo-doctors.svg';
import LogoLawyers from 'assets/images/logo-lawyers.svg';
import LogoVets from 'assets/images/logo-vets.svg';
import Linkedin from 'assets/images/linkedin.png'


/* Logos */
import Asisa from 'config/md/assets/logos/ASISA.png'
import Bayer from 'config/md/assets/logos/bayer-1.png'
import Caser from 'config/md/assets/logos/CASER.png'
import Isalud from 'config/md/assets/logos/ISALUDlogo.png'
import Nationale from 'config/md/assets/logos/nationale.png'
import Savia from 'config/md/assets/logos/SAVIA.png'
import Yoigo from 'config/md/assets/logos/yoigo.png'
import Helvetia from 'config/md/assets/logos/helvetia.png'
import Fiatc from 'config/md/assets/logos/fiatc.svg'
import GlobalFinanz from 'config/md/assets/logos/globalfinanz.png'
import Rimac from 'config/md/assets/logos/rimac.svg'
import Vivaz from 'config/md/assets/logos/vivaz.png'
import Zurich from 'config/md/assets/logos/zurich.png'
import Divina from 'config/md/assets/logos/divina-pastora.svg'
import Acunsa from 'config/md/assets/logos/acunsa.png'
import Aegon from 'config/md/assets/logos/aegon.png'
import Alan from 'config/md/assets/logos/alan.png'
import Asterra from 'config/md/assets/logos/asterra-partners.png'
import Aura from 'config/md/assets/logos/aura-seguros.png'
import Cnp from 'config/md/assets/logos/cnp.png'
import Generali from 'config/md/assets/logos/generali-seguros.png'
import Hna from 'config/md/assets/logos/hna.png'
import Mediolanum from 'config/md/assets/logos/mediolanum-banco.png'
import MutuaCat from 'config/md/assets/logos/mutua-cat.png'
import MutuaTerrassa from 'config/md/assets/logos/mutua-terrassa.png'
import RealeSeguros from 'config/md/assets/logos/reale-seguros.png'
import TopDoctors from 'config/md/assets/logos/top-doctors.png'
import Pepsico from 'config/md/assets/logos/pepsico.png'
import Bayzat from 'config/md/assets/logos/Bayzat.svg'
import Jumia from 'config/md/assets/logos/Jumia.svg'
import logo5CN from 'config/md/assets/logos/5CN.svg'

import videoTelehealth from 'config/md/assets/video/MD_Telehealth.mp4'
import posterVideo from 'config/md/assets/video/poster_video.jpg'

import MilestoneBackground from 'config/md/assets/images/bg-milestone.jpg'


/* Testimonios */
import Testimonial1 from 'config/md/assets/images/testimonial-1.png'
import Testimonial2 from 'config/md/assets/images/testimonial-2.png'
import Testimonial3 from 'config/md/assets/images/testimonial-3.png'
import Testimonial4 from 'config/md/assets/images/testimonial-4.png'
import Testimonial5 from 'config/md/assets/images/testimonial-5.png'
import Testimonial6 from 'config/md/assets/images/testimonial-6.png'
import Testimonial7 from 'config/md/assets/images/testimonial-7.png'
import Testimonial8 from 'config/md/assets/images/testimonial-8.png'
import Spain from "assets/flags/spain.png";
import Portugal from "assets/flags/portugal.png";
import UK from "assets/flags/uk.png";
import { Recaptcha } from "components/form/recaptcha";
import {
  AE_OFFICE_ADDRESS,
  AE_OFFICE_URL,
  BCN_OFFICE_ADDRESS,
  BCN_OFFICE_URL,
  MD_URL,
  ML_URL,
  MV_URL,
  MX_OFFICE_ADDRESS,
  MX_OFFICE_URL
} from "config/constants";
import { Trans } from "react-i18next";
import React from "react";
import { InputField } from "components/common/fields/input";
import { TextareaField } from "components/common/fields/textarea";

const DOCTORGO_URL = "https://doctorgo.es/assets/images/home/doctor-go.mp4"
const GINECANESCHAT = "https://www.youtube.com/embed/T5SbZh2DslI";
const LINKEDIN_MD = "https://www.linkedin.com/company/meetingdoctors";

// const manageLegalTranslated = () => {
//   const currentLanguage = localStorage.getItem('i18nextLng');
//   if(currentLanguage==="es") return  'https://docs.google.com/document/d/e/2PACX-1vSCScxiatXK3zJeltjwoHtCHIzEYd4piTq8y0_9ztSnv6I1a7QpEplv5mzZ4IG3q_XOfpNvkX3GIHkr/pub';
//   return 'https://docs.google.com';
// }

export const MD_MODEL_CONFIG = {
  header: {
    logo: LogoHeader,
    links: [
      { text: 'headerLink1', href: '#clients' },
      { text: 'headerLink2', href: '#offer' },
      { text: 'headerLink3', href: '#our' },
      { text: 'headerLink4', href: '#milestone' },
      { text: 'headerLink5', href: '#location' },
      { text: 'headerLink6', href: '#footer' },
    ],
    languageSelector: {
      languages: [
        { text: 'Español', value: 'es', icon: Spain },
        { text: 'Português', value: 'pt', icon: Portugal },
        { text: 'English', value: 'en', icon: UK }
      ]
    },
    backLink: { text: 'backLink', href: '/' },
  },
  intro: {
    image: Intro,
    title: { key: 'introTitle', value: ['El servicio de ', 'consulta médica digital B2B'] },
    subTitle: 'introSubtitle',
  },
  features: [
    { title: 'titleFeature1', text: 'textFeature1', icon: 'calendar' },
    { title: 'titleFeature2', text: 'textFeature2', icon: 'doctors' },
    { title: 'titleFeature3', text: 'textFeature3', icon: 'leaders' },
    { title: 'titleFeature4', text: 'textFeature4', icon: 'time' }
  ],
  clients: {
    title: 'clientsTitle',
    images: [
      Asisa, Bayer, Caser, Isalud, Nationale, Savia, Yoigo, Helvetia, Fiatc, GlobalFinanz,
      Rimac, Vivaz, Zurich, Divina, Acunsa, Aegon, Hna, Alan, Cnp, MutuaCat, MutuaTerrassa,
      TopDoctors, RealeSeguros, Mediolanum, Generali, Aura, Asterra, Pepsico, Bayzat, Jumia, logo5CN
    ]
  },
  success: {
    title: 'successTitle',
    videos: [
      { srcImage: DoctorGo, srcVideo: DOCTORGO_URL },
      { srcImage: Ginecaneschat, srcVideo: GINECANESCHAT },
    ]
  },
  offer: {
    title: 'offerTitle',
    subtitle: 'offerSubtitle',
    image: MobileGif,
    video: videoTelehealth,
    poster: posterVideo,
    list: [
      { strongText: 'offerTextStrong1', lightText: 'offerTextLight1' },
      { strongText: 'offerTextStrong2', lightText: 'offerTextLight2' },
      { strongText: 'offerTextStrong3', lightText: null },
      { strongText: 'offerTextStrong4', lightText: 'offerTextLight4' },
      { strongText: 'offerTextStrong5', lightText: 'offerTextLight5' },
    ]
  },
  our: {
    title: 'ourTitle',
    subtitle: 'ourSubtitle',
    image: MobilePic,
    tags: {
      text: 'ourTagsText',
      list: [
        'tag1', 'tag2', 'tag3', 'tag4', 'tag5', 'tag6', 'tag7', 'tag8', 'tag9', 'tag10', 'moreTags'
      ]
    },
    list: [
      { strongText: 'ourStrongText1', lightText: 'ourLightText1' },
      { strongText: 'ourStrongText2', lightText: 'ourLightText2' },
      { strongText: 'ourStrongText3', lightText: 'ourLightText3' },
    ]
  },
  milestone: {
    title: 'milestoneTitle',
    image: MilestoneBackground,
    list: [
      { number: "100.000", text: 'milestoneText1' },
      { number: "97%", text: 'milestoneText2' },
      { number: "70%", text: 'milestoneText3' },
      { number: "4,5", text: 'milestoneText4' },
    ]
  },
  testimony: {
    title: 'testimonyTitle',
    list: [
      { avatar: Testimonial1, name: 'Ana V.', text: 'testimonyText1' },
      { avatar: Testimonial2, name: 'Antonio R.', text: 'testimonyText2' },
      { avatar: Testimonial3, name: 'Maria B.', text: 'testimonyText3' },
      { avatar: Testimonial4, name: 'Joan V.', text: 'testimonyText4' },
      { avatar: Testimonial5, name: 'Sergi B.', text: 'testimonyText5' },
      { avatar: Testimonial6, name: 'Encarna F.', text: 'testimonyText6' },
      { avatar: Testimonial7, name: 'Carles R.', text: 'testimonyText7' },
      { avatar: Testimonial8, name: 'Mireia S.', text: 'testimonyText8' },
    ]
  },
  data: [
    { icon: 'chat', strongText: 'dataStrongText1', lightText: 'dataLightText1' },
    { icon: 'pioners', strongText: 'dataStrongText2', lightText: 'dataLightText2' },
    { icon: 'leaders', strongText: 'dataStrongText3', lightText: 'dataLightText3' },
  ],
  location: {
    title: 'locationTitle',
    subtitle: 'locationSubtitle',
    image: Map,
  },
  contact: {
    title: 'contactTitle',
    fields: [
      {
        name: 'name',
        component: InputField,
        type: 'text',
        label: 'fieldNameLabel',
        placeholder: 'fieldNamePlaceholder'
      },
      {
        name: 'email',
        component: InputField,
        label: 'fieldEmailLabel',
        placeholder: 'fieldEmailPlaceholder'
      },
      {
        name: 'message',
        component: TextareaField,
        label: 'fieldMessageLabel',
        placeholder: 'fieldMessagePlaceholder',
      },
      { name: 'recaptcha', component: Recaptcha }
    ],
    button: 'contactButton'
  },
  footer: {
    logos: [
      { src: LogoDoctors, link: MD_URL },
      { src: LogoVets, link: MV_URL },
      { src: LogoLawyers, link: ML_URL },
    ],
    links: [
      { type: 'redirect', text: 'footerLink1', link: '/legal-warning' },
      { type: 'text', text: 'footerLink2', link: 'mailto:info@meetingdoctors.com' },
      { type: 'text', text: 'footerLink3', link: 'mailto:soporte@meetingdoctors.com' },
      { type: 'image', src: Linkedin, link: LINKEDIN_MD },
    ],
    addresses: [
      { text: BCN_OFFICE_ADDRESS, link: BCN_OFFICE_URL },
      { text: MX_OFFICE_ADDRESS, link: MX_OFFICE_URL },
      { text: AE_OFFICE_ADDRESS, link: AE_OFFICE_URL },
    ],
    copyright: 'copyrightText',
  },
  floatingButton: 'floatingButton',
  legalWarning: {
    title: 'Términos y condiciones de Uso MeetingDoctors',
    text: `MeetingDoctors S. L. (en adelante, MeetingDoctors) es una aplicación informática 
    diseñada para teléfonos móviles inteligentes que ofrece un servicio de chat médico y 
    veterinario 24x7 para resolver consultas de salud y aportar tranquilidad a sus usuarios. 
    Éstos son los datos identificativos y de contacto de MeetingDoctors:`,
    identificationData: 'DATOS IDENTIFICATIVOS',
    contactData: 'DATOS DE CONTACTOS',
    list: [
      { text: 'Domicilio Social', value: 'Calle del Milanesado, 25-27 Planta 5 de Barcelona (08017)' },
      { text: 'Registro Mercantil', value: 'Registro Mercantil de Barcelona, Tomo 45780, Folio 39, Hoja B498734' },
      { text: 'Número de identificación fiscal', value: 'B66940982' },
      { text: 'Puedes contactar con MeetingDoctors', value: 'Por mail: soporte@meetingdoctors.com' },
      { text: 'Puedes ejercitar tus derechos sobre protección de datos', value: 'Por mail: dpo@meetingdoctors.com' },
    ]
  },
}

