import React from 'react';
import Header from "components/header";
import Intro from "components/intro";
import Features from "components/features";
import Clients from "components/clients";
import Success from "components/success";
import Offer from "components/offer";
import Our from "components/our";
import Location from "components/location";
import Milestone from "components/milestone";
import Testimony from "components/testimony";
import Data from "components/data";
import Footer from "components/footer";
import Floating from "components/floating";
import CustomReduxForm from "components/form";
import { GlobalWrapper } from 'App'
import './style.scss';


const Home = ({ company }) => {
  return (
    <GlobalWrapper config={ company }>
      <Header/>
      <Intro/>
      <Features/>
      <Clients/>
      <Success/>
      <Offer/>
      <Our/>
      <Milestone/>
      <Testimony/>
      <Data/>
      <Location/>
      <CustomReduxForm/>
      <Footer/>
      <Floating/>
    </GlobalWrapper>
)
}

export default Home;
