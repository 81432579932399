import {MD_MODEL_CONFIG} from "config/md/constants";
import {MV_MODEL_CONFIG} from "config/mv/constants";
import {MD, MV} from "./constants";


export const useCompany = () => {
  let companyConf = {
    components: null,
    theme: null,
  }
  //Take company from environment
  let {REACT_APP_COMPANY} = process.env;
  // REACT_APP_COMPANY = 'meetingvets';
  //Take company from url modify when deployin
  // const companyFromUrl = window.location.hostname.split('.')[0];

  if(REACT_APP_COMPANY === MD) {
    companyConf.components=MD_MODEL_CONFIG
    companyConf.theme='meeting-doctors'
  }
  if(REACT_APP_COMPANY === MV) {
    companyConf.components=MV_MODEL_CONFIG
    companyConf.theme='meeting-vets'
  }
  return companyConf;
}
export const getCompanyLocize = () => {
  const {
    REACT_APP_LOCIZE_PROJECT_ID,
    REACT_APP_LOCIZE_API_KEY,
    REACT_APP_LOCIZE_LANGUAGES
  } = process.env;
  // const companyFromUrl = window.location.hostname.split('.')[0];
  const languages = REACT_APP_LOCIZE_LANGUAGES.split(',');

  const locizeOptions = {
    projectId: REACT_APP_LOCIZE_PROJECT_ID,
    apiKey: REACT_APP_LOCIZE_API_KEY,
    availableLanguages: languages,
    referenceLng: 'es',
    crossDomain: true,
  }
  return locizeOptions;
}

