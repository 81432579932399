
export const COPYRIGHT = 'Copyright © 2021 Meeting Doctors SL. Todos los derechos reservados'
export const PLACE1 = 'C. Milanesat, 25, 08017 Barcelona'
export const PLACE2 = 'C. Darwin, 74, 11590, CDMX, México'
export const PLACE3 = 'Jumeirah Lake Towers, Dubai, EAU'
export const MD_WEB = 'https://www.meetingdoctors.com/'
export const MV_WEB = 'https://www.meetingvets.com/'
export const ML_WEB = 'https://www.meetinglawyers.com/'
export const LINKEDIN = 'https://www.linkedin.com/company/meetingdoctors/'
export const BARCELONA = 'https://goo.gl/maps/qWd9enCFJsmJn5yr8'
export const MEXICO = 'https://goo.gl/maps/bQs1MCNmwpGDLhWZ9'
export const DUBAI = 'https://goo.gl/maps/r3aYE1mebrNkwxFE9'
export const TEXT = 'text'
export const REDIRECT = 'redirect'
export const IMAGE = 'image'