import React, { useEffect } from 'react';
import {Provider} from "react-redux";
import store from "store/store";
import { manageScroll } from './utilities/scroll'
import { useCompany } from "config/useCompany"
import { Router } from "@reach/router"
import LegalWarning from "pages/legalWarning/index";
import Home from "pages/home/index";
import AOS from 'aos';
import 'aos/dist/aos.css';
import './app.scss';

export const GlobalWrapper = ({ children, config }) => {
  return children.map( item => {
    let newItem = { ...item }
    newItem.props = { ...item.props, config }
    return newItem
  })
}

const App = () => {
  AOS.init();
  const company = useCompany()
  useEffect(() => {
    manageScroll()
  },[])

  return (
    <Provider store={store}>
      <div className={company.theme}>
        <Router>
          <Home path="/" company={company} />
          <LegalWarning path="/legal-warning" company={company} />
        </Router>
      </div>
    </Provider>
  );
}

export default App;
