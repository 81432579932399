import React from 'react';
import './style.scss';
import {useTranslation} from "react-i18next";

const Data = ({config: {components}}) => {
  const {data} = components;
  const {t} = useTranslation();
  
  const createContent = () => (
    data.map((item, key) => (
        <div className="item" key={key}>
          <div className={`icon icon-${item.icon}`}  data-aos="fade-down"></div>
          <div className="title"  data-aos="fade-up">{t(item.strongText)}</div>
          <div className="description"  data-aos="fade-up">{t(item.lightText)}</div>
        </div>
      )
    ))
  return (
    <section id="data">
      <div className="wrap">
        <div className="list">
          {createContent()}
        </div>
      </div>
    </section>
  )
};
export default Data;
