import React from 'react';
import styled from 'styled-components';
import './style.scss';
import {useTranslation} from "react-i18next";
import {Trans} from "react-i18next";

const Intro = ({config: {components}}) => {
  const {intro} = components;
  const {t} = useTranslation()
  const createIntroTitle = () =>{
    return <Trans i18nKey={intro.title.key}>{intro.title.value.map(text =><span>{text}</span>)}</Trans>
  }
  return (
    <section className="intro" id="intro">
      <Parallax src={intro.image}/>
      <div className="wrap">
        <div className="text">
          <div className="main-text">
            {createIntroTitle()}
          </div>
          <div className="secondary-text">{t(intro.subTitle)}</div>
        </div>
      </div>
    </section>
  )
};
export default Intro;

const Parallax = styled.div.attrs(({
  className:'intro-parallax'
}))`
  height: 100%;
  background: url(${props => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
