import React, { useState } from 'react';
import Underline from 'components/common/underline'
import { ImageVideo, Video } from './templates'
import './style.scss';
import {useTranslation} from "react-i18next";
import WrapperSection from "components/common/wrapperSection";


const Success = ({config: {components}}) => {
  const [ urlVideo, setUrlVideo ] = useState(null)
  const {t} = useTranslation();
  const {success} = components;

  const createVideos = () => {
    return success.videos.map(video => {
      let newVideo = {...video,setUrlVideo}
      return <ImageVideo {...newVideo}/>
    })
  }
  const videoParams = { setUrlVideo, urlVideo }

  return (
    <>
      <WrapperSection id="success">
        <Underline>{ t(success.title) }</Underline>
        <div className="images-wrapper">
          {createVideos()}
        </div>
      </WrapperSection>
      { urlVideo && <Video { ...videoParams } /> }
    </>
  )
};
export default Success;
