import React from 'react';
import Image from 'components/common/image'
import './style.scss';
import {useTranslation} from "react-i18next";
import WrapperSection from "components/common/wrapperSection";


const Our = ({config: {components}}) => {
  const {our} = components;
  const {t} = useTranslation();
  const createSpeciality = () => (our.tags.list.map( item => <span>{ t(item) }</span>))

  const createParagraph = () => (
  our.list.map( item => (
      <div className="item">
        <div className="main">{ t(item.strongText) }</div>
        <div className="text">{ t(item.lightText) }</div>
      </div>
    ))
  )

  return (
    <WrapperSection className="our" id="our">
        <div className="text">
          <div className="title">{ t(our.title)}</div>
          <div className="subtitle">{ t(our.subtitle) }</div>
          <div className="speciality">{t(our.tags.text)}{ createSpeciality() }</div>
          <div className="paragraphs">{ createParagraph() }</div>
        </div>
        <Image className="image" src={ our.image } data-aos="fade-down-left"/>
    </WrapperSection>
  )
};
export default Our;
