import React, { useEffect } from 'react';
import {useTranslation} from "react-i18next";
import Header from "components/header";
import Footer from "components/footer";
import { GlobalWrapper } from 'App'
import { createItems } from "./actions"
import { scrollTop } from "utilities/scroll"
import './style.scss';

const LegalWarning = ({ company }) => {
  const {t} = useTranslation()
  const { 
    title, text, identificationData, contactData, list 
  } = company?.components?.legalWarning

  useEffect(() => {
    scrollTop()
  },[])

  return (
    <div className="legal-warning-page">
      <GlobalWrapper config={ company }>
       <Header/>
       <div className="legal-warning">
        <div className="legal-wrap">
            <div className="legal-wrap__content">
              <h3>{ title }</h3>
              <div className="legal-wrap__intro">{ text }</div>
              <div className="legal-wrap__table">
                 <h4>{ identificationData }</h4>
                 <div className="item-container">{ createItems(list.slice(0,3)) }</div>
                 <h4>{ contactData }</h4>
                 <div className="item-container">{ createItems(list.slice(3,5)) }</div>
              </div>
            </div> 
        </div>
       </div>
       <Footer/>
      </GlobalWrapper>
    </div>
  )
};
export default LegalWarning
