import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "@reach/router";
import styled from 'styled-components';
import { TEXT, REDIRECT, IMAGE } from './constants';
import './style.scss';


const Footer = ({ config: { components } }) => {

  const { footer } = components;
  const { t } = useTranslation();

  const createLogos = () => (footer.logos.map(logo => (
    <a href={logo.link}>
      <Image src={logo.src} />
    </a>
  )))
  const createLinks = () => (footer.links.map(item => {
    if (item.type === TEXT) return <a href={item.link}>{t(item.text)}</a>
    if (item.type === REDIRECT) return <Link to={item.link}>{t(item.text)}</Link>
    if (item.type === IMAGE) return <a href={item.link}><Image src={item.src} className="linkedin" /></a>
  }))
  const createAddresses = () => (footer.addresses.map(address =>
    <a href={address.link} target="_blank">{address.text}</a>))

  return (
    <section id="footer">
      <div className="wrap">

        <div className="links">
          <div className="images">
            {createLogos()}
          </div>
          <div className="external-pages">
            {createLinks()}
          </div>
          <div className="links-map">
            {createAddresses()}
          </div>
        </div>

        <div className="copyright">{t(footer.copyright)}</div>
      </div>


    </section>
  )
};
export default Footer;

const Image = styled.div`
  background: url(${props => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  width: 268px;
  height: 32px;

  &:hover {
    opacity: 0.5;
  }
`;
