import React from 'react';
import {useTranslation} from "react-i18next";
export const TextareaField = ({input,placeholder, meta}) => {
  const {t} = useTranslation();
  return(
    <>
      <textarea
        rows={4}
        placeholder={placeholder}
        value={input.value}
        onChange={e =>input.onChange(e.target.value)}
        onFocus={ input.onFocus }
        onBlur={ input.onBlur }
      />
      {meta && meta.touched && meta.error && <span className="error-validation">{t(meta.error)}</span>}

    </>
  )

}
