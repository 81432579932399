import React, { useEffect, useState } from 'react';
import Left from 'assets/images/chevron-left.svg'
import Right from 'assets/images/chevron-right.svg'
import styled from 'styled-components'

import './style.scss';

const Carousel = ({ children, breakPoint, kind }) => {
    const [mobile, setMobile] = useState(null)
    const [index, setIndex] = useState(0)
    const items = children.map( item => item )
    const next = value => setIndex(++value%(items.length-1))
    const previous = value => ((value === 0) ? setIndex(items.length-2) : setIndex(--value%(items.length-1)))

    const createDots = () => {
      let dots = items.map((item,key) =>{
        let activeClass = key ===index && 'active';
        return (<div className={activeClass} onClick={()=>setIndex(key)}/>)
      });

      return <div className="dots-wrapper">{dots}</div>
    }

    const manageScreen = () => {
      let screenWidth = window.innerWidth
      // si estaba desktop y ahora es más pequeño
      if (screenWidth < breakPoint) setMobile(true)
      // si estaba mobile y ahora en desktop
      else if(screenWidth >= breakPoint) setMobile(false)
    }

    useEffect(() => {
        manageScreen()
        window.addEventListener('resize', () => manageScreen(), true);
    }, [])

    useEffect(()=>{
      const intervalCarousel = setTimeout(()=>next(index),1000 *5);
      return ()=>clearInterval(intervalCarousel)
    },[index])
    let rowElem
    if(kind === 'clients') rowElem = mobile ? items[index] : items.slice( index, index+2 )
    else if(kind === 'testimony') rowElem = mobile ? items[index] : items.slice( index, index+2 )
    else rowElem = items[index]

    return (
      <>
        <div className="carousel-custom">
          <Image className="left" src={ Left } onClick={ () => previous(index) }/>
          <div className="content">{ rowElem }</div>
          <Image className="right" src={ Right } onClick={ () => next(index) }/>
          {/* <div className="dots"></div> */}
        </div>
        {/*{createDots()}*/}
      </>

    )
}

export default Carousel

export const Image = styled.div`
    background: url(${props => props.src});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
`;
