import React from 'react';
import Image from 'components/common/image'
import './style.scss';
import { useTranslation } from "react-i18next";
import WrapperSection from "components/common/wrapperSection";


const Offer = ({ config: { components } }) => {
  const { offer } = components;
  const { t } = useTranslation();

  const urlVideo = window.location.protocol + '//' + window.location.host + offer.video

  const createListItems = () => {
    return offer.list.map(({ strongText, lightText }) => (
      <div className="item"><i className="icon-check" /><span>{strongText &&
        <strong>{t(strongText)}</strong>}{t(lightText)}</span></div>
    ))
  }
  return (
    <WrapperSection className="offer" id="offer">
      <div className="text">
        <video className='video' poster={offer.poster} controls data-aos="fade-down-right">
          <source src={urlVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div className='wraperInfo'>
          <div className="title">{t(offer.title)}</div>
          <div className="subtitle">{t(offer.subtitle)}</div>
          {createListItems()}
        </div>
      </div>
    </WrapperSection>
  )
};
export default Offer;
