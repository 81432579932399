import React from 'react';
import './style.scss';
import {useTranslation} from "react-i18next";
import WrapperSection from "components/common/wrapperSection";


const Features = ({config:{components}}) => {
  const {features} = components;
  const {t} = useTranslation()

  const createContent = () => {
    return features.map(feature => {
      return (
        <div className="item" key={feature.title}>
          <i className={`icon-${feature.icon}`}/>
          <div className="side">
            <div className="title">{t(feature.title)}</div>
            <div className="description">{t(feature.text)}</div>
          </div>
        </div>
      )
    })
  }

  return (
    <WrapperSection id="features">
      <div className="features-container">
        { createContent() }
      </div>
    </WrapperSection>
  )
};
export default Features;
