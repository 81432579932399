import React, {useState} from 'react';
import Carousel from '../common/carousel'
import Underline from 'components/common/underline'
import './style.scss';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import WrapperSection from "components/common/wrapperSection";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Pagination, Navigation, Autoplay} from "swiper/core";
import Left from "assets/images/chevron-left.svg";
import Right from "assets/images/chevron-right.svg";
import {getElem} from "components/testimony/actions";

SwiperCore.use([Autoplay, Pagination, Navigation]);


const Clients = ({config: {components}}) => {
  const {clients} = components;
  const {t} = useTranslation();
  const [node, setNode] = useState(null)

  const images = clients.images.map((image, id) => (<Image src={image} key={id}/>))
  const Grid = slides => {
    return (
      <div className="grid">
        {slides.children.map(item => item)}
      </div>
    )
  }
  const AUTOPLAY = {
    "delay": 5000,
    "disableOnInteraction": false
  }
  return (
    <div id="clients">
      <Underline>{t(clients.title)}</Underline>
      <div className="slider-wrapper">
        <Button left src={Left} onClick={() => document.querySelector('.clients-slider').swiper.slidePrev()}/>
        <Swiper
          loop={true}
          spaceBetween={10}
          autoplay={AUTOPLAY}
          slidesPerView={2}
          pagination={{
            "type": "fraction"
          }}
          navigation={true}
          //className="carrousel"
          className="clients-slider"
        >
          {clients.images.map((image, id) => (<SwiperSlide><Image src={image} key={id}/></SwiperSlide>))}
        </Swiper>
        <Button right src={Right} onClick={() => document.querySelector('.clients-slider').swiper.slideNext()}/>
      </div>
      <Grid className="grid">{images}</Grid>
    </div>
  )
};
export default Clients


export const Image = styled.div`
  background: url(${props => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100px;
  //border: 10px solid transparent;
  box-sizing: border-box;
`;
const Button = styled.div`
    background: url(${props => props.src});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    min-width: 30px;
    height: 30px;
`;
