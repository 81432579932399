
import React, { useState } from 'react'
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation,Autoplay } from "swiper/core";
import { getElem } from "./actions"
import { AUTOPLAY, BREAKPOINTS } from './constants'
import Left from 'assets/images/chevron-left.svg'
import Right from 'assets/images/chevron-right.svg'
import styled, { css } from "styled-components";

// install Swiper modules
SwiperCore.use([Autoplay,Pagination, Navigation]);

export const Slider = ({ t, testimony }) => {
    return (
      <Swiper
        loop={true}
        spaceBetween={10}
        autoplay={AUTOPLAY}
        breakpoints={BREAKPOINTS}
        pagination={{"clickable": true}}
        navigation={true}
        //className="carrousel"
        className="mySwiper"
        >
        {
            testimony.list.map((item) => (
            <SwiperSlide>
                <Avatar src={item.avatar}/>
                <div className="name">{item.name}</div>
                <div className="text">{t(item.text)}</div>
            </SwiperSlide>
            ))
        }
      </Swiper>
    )
}

export const CustomSlider = props => {
    return (
      <div className="custom-slider">
        <Button left src={ Left } onClick={ () => document.querySelector('.mySwiper').swiper.slidePrev() } />
        <Slider { ...props } />
        <Button right src={ Right } onClick={ () => document.querySelector('.mySwiper').swiper.slideNext() } />
      </div>
    )
}

export const createSlides = ({ testimony, t }) => (
    testimony.list.map((item) => (
        <div className="item">
          <Avatar src={item.avatar}/>
          <div className="name">{item.name}</div>
          <div className="text">{t(item.text)}</div>
        </div>
    ))
)

export const Button = styled.div`
    background: url(${props => props.src});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 46%;
    ${({ left }) => left && css`
      left: -26px;
   `}
   ${({ right }) => right && css`
      right: -26px;
   `}
`;

export const Avatar = styled.div.attrs({
    className: "avatar"
  })`
    width: 80px;
    height: 80px;
    background: url(${props => props.src});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: -40px;
    border-radius: 50%;
  `
