
export const AUTOPLAY = {
    "delay": 5000,
    "disableOnInteraction": false
}

export const BREAKPOINTS = {
    // "640": {
    //     "slidesPerView": 1,
    // },
    // "700": {
    //     "slidesPerView": 1,
    // },
    "1100": {
        "slidesPerView": 2,
    },
    "1240": {
        "slidesPerView": 3,
    }
}