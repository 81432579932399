import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import {useTranslation} from "react-i18next";

export const Recaptcha = ({input,placeholder, meta}) => {
    const {t} = useTranslation();
    return (
      <>
          <ReCAPTCHA
            sitekey={"6LcgzcgaAAAAAHVIPQVF6GgAHVgJvvE2mst_PIHI"}
            input={input.value}
            onChange={input.onChange}
            className="recaptacha"
          />
          {meta && meta.touched && meta.error && <span className="error-validation">{t(meta.error)}</span>}
      </>

    )
};
